.autocomplete {
  position: relative;
  display: inline-block;
}

.autocomplete input {
  border: 1px solid #00000047;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  color: currentColor;
}


.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.container{
  width: 300px;
  padding: 20px;
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
}
.did-floating-label-content { 
  position:relative; 
  margin-bottom:20px; 
}
.did-floating-label {
  color:#1e4c82; 
  font-size:13px;
  font-weight:normal;
  position:absolute;
  pointer-events:none;
  left:15px;
  top:11px;
  padding:0 5px;
  background:#fff;
  transition:0.2s ease all; 
  -moz-transition:0.2s ease all; 
  -webkit-transition:0.2s ease all;
}
.did-floating-input, .did-floating-select {
  font-size:12px;
  display:block;
  width:100%;
  height:36px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid #3D85D8;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus{
    outline:none;
    ~ .did-floating-label{
      top:-8px;
      font-size:13px;
    }
  }
}

select.did-floating-select {
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
select.did-floating-select::-ms-expand {
  display: none;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top:-8px;
  font-size:13px;
}
.did-floating-select:not([value=""]):valid ~ .did-floating-label {
  top:-8px;
  font-size:13px;
}
.did-floating-select[value=""]:focus ~ .did-floating-label {
  top:11px;
  font-size:13px;
}
.did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
    background-position: right 15px top 50%;
    background-repeat: no-repeat;
}

.did-error-input{
  .did-floating-input, .did-floating-select {
    border: 2px solid #9d3b3b;
    color:#9d3b3b;
  }
  .did-floating-label{
    font-weight: 600;
    color:#9d3b3b;
  }
  .did-floating-select:not([multiple]):not([size]) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
}
}

.input-group {
  display: flex;
  .did-floating-input{
    border-radius:0 4px 4px 0;
    border-left:0;
    padding-left:0;
  }
}
.input-group-append {
  display:flex;
  align-items:center;
/*   margin-left:-1px; */
}
.input-group-text {
    display: flex;
    align-items: center;
    font-weight: 400;
    height:34px;
    color: #323840;
    padding: 0 5px 0 20px;
  font-size:12px;
    text-align: center;
    white-space: nowrap;
    border: 1px solid #3D85D8;
  border-radius: 4px 0 0 4px;
  border-right:none;
}